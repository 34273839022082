@font-face {
  font-family: 'hotelmapy';
  src: url('../fonts/hotelmapy.eot?kre2f6');
  src: url('../fonts/hotelmapy.eot?kre2f6#iefix') format('embedded-opentype'),
    url('../fonts/hotelmapy.ttf?kre2f6') format('truetype'),
    url('../fonts/hotelmapy.woff?kre2f6') format('woff'),
    url('../fonts/hotelmapy.svg?kre2f6#hotelmapy') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon_'],
[class*=' icon_'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'hotelmapy' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon_u_bahn:before {
  content: '\75';
}
.icon_s_bahn .path1:before {
  content: '\73';
  color: rgb(0, 0, 0);
}
.icon_s_bahn .path2:before {
  content: '\74';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local('Material Icons'), local('../fonts/MaterialIcons-Regular'),
    url(../fonts/MaterialIcons-Regular.woff2) format('woff2'),
    url(../fonts/MaterialIcons-Regular.woff) format('woff'),
    url(../fonts/MaterialIcons-Regular.ttf) format('truetype');
} /* roboto-300 - latin */

/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v53/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2)
    format('woff2');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/roboto-v20-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Roboto Light'), local('Roboto-Light'),
    url('../fonts/roboto-v20-latin-300.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/roboto-v20-latin-300.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/roboto-v20-latin-300.woff')
      format('woff'),
    /* Modern Browsers */ url('../fonts/roboto-v20-latin-300.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/roboto-v20-latin-300.svg#Roboto')
      format('svg'); /* Legacy iOS */
}
/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/roboto-v20-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Roboto Medium'), local('Roboto-Medium'),
    url('../fonts/roboto-v20-latin-500.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/roboto-v20-latin-500.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/roboto-v20-latin-500.woff')
      format('woff'),
    /* Modern Browsers */ url('../fonts/roboto-v20-latin-500.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/roboto-v20-latin-500.svg#Roboto')
      format('svg'); /* Legacy iOS */
}
/* roboto-italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/roboto-v20-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Roboto Italic'), local('Roboto-Italic'),
    url('../fonts/roboto-v20-latin-italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/roboto-v20-latin-italic.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/roboto-v20-latin-italic.woff')
      format('woff'),
    /* Modern Browsers */ url('../fonts/roboto-v20-latin-italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../fonts/roboto-v20-latin-italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/roboto-v20-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Roboto'), local('Roboto-Regular'),
    url('../fonts/roboto-v20-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/roboto-v20-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/roboto-v20-latin-regular.woff')
      format('woff'),
    /* Modern Browsers */ url('../fonts/roboto-v20-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../fonts/roboto-v20-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/roboto-v20-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Roboto Bold'), local('Roboto-Bold'),
    url('../fonts/roboto-v20-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/roboto-v20-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/roboto-v20-latin-700.woff')
      format('woff'),
    /* Modern Browsers */ url('../fonts/roboto-v20-latin-700.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/roboto-v20-latin-700.svg#Roboto')
      format('svg'); /* Legacy iOS */
}
