$locations-detail-content-inset: $spacing-SM * 1.75;
$defined-iframe-max-height: 500px;

.location-details {
  position: relative;
  z-index: 300;
  display: grid;
  -ms-overflow-style: none;
  grid-template-rows: auto $spacing-XS auto;
  grid-template-columns: auto 1fr 1fr;
  background: $white;
  border-radius: 8px;
  box-shadow: 0px 1px 4px rgba(#000, 0.2);
  border: solid 1px var(--primary-color-lighter-28);
  overflow: auto;

  @include xs-down {
    animation-name: enteringDescription;
    animation-duration: 0.2s;
    transition-timing-function: ease-out;
    grid-row: 3 / 7;
    grid-column: 1/3;
    margin-left: $spacing-SM * 1.5;
    margin-right: $spacing-SM * 1.5;
    margin-top: $spacing-SM * 1.5;
    height: 100%;
    max-height: 97.5%;
  }

  @include sm-up {
    z-index: 500;
    grid-row: 1 / 3;
    grid-column: 1/2;
    border-radius: 0px;
  }

  @include lg-up {
    grid-row: 2 / 4;
    grid-column: 2/3;
    margin-top: 16px;
    border-radius: 8px;
    margin-bottom: 16px;
    margin-left: 16px;
  }
  @include xl-up {
    -ms-grid-row: 2 !important;
    -ms-grid-row-span: 2;
    grid-row: 2 / 4;
    -ms-grid-column: 2 !important;
    -ms-grid-column-span: 1;
    grid-column: 2/3;
  }
  @media (max-height: $defined-iframe-max-height) and (max-width: 576px) {
    display: grid;
    z-index: 500;
    grid-row: 1 /7;
    grid-column: 1 / 3;
    margin-top: $spacing-MD;
    border-radius: 8px;
  }
  @media (max-height: $defined-iframe-max-height) and (min-width: 576px) {
    display: grid;
    grid-template-rows: auto;
    z-index: 500;
    grid-row: 1 / 6;
    grid-column: 1 / 2;
    margin-top: 0;
    margin-bottom: 0px;
    margin-left: 0;
    border-radius: 0;
    box-shadow: 0px -1px 3px 0px rgba(0, 0, 0, 0.1);
  }

  &__close-button-container {
    z-index: 4;
    display: flex;

    justify-content: center;
    padding: 4px;
    @extend %material-icon;
    border: none;
    outline: none;
    position: absolute;
    right: 0;
    grid-row: 1;
    grid-column: 3;
    :focus {
      outline: none;
    }
  }

  &__close-button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 44px;
    height: 44px;
    color: $black-54;
    font-size: 24px;
    text-shadow: none;
    border: none;
    outline: none;
    background-color: transparent;
    @extend %material-icon;
  }

  &__image {
    grid-row: 1/2;
    grid-column: 1 / 5;
    width: 100%;
    height: 180px;
    object-fit: cover;

    img {
      background: rgba(#000, 0.15);
    }
    @media (max-height: $defined-iframe-max-height) {
      display: grid;
      flex-direction: row;
      grid-row: 1;
      grid-column: 1 / 2;
      height: 100px;
      padding: $locations-detail-content-inset;
    }
  }

  &__description {
    grid-column: 1/4;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 0;
      height: 0;
    }
    @extend %font-sans-MD;
    height: 100%;
  }

  &__description-text {
    color: $text-dark-primary;
  }

  &__adress {
    @extend %font-sans-MD;
    color: $text-dark-secondary;

    margin-bottom: $spacing-XS * 1;
    @media (max-height: $defined-iframe-max-height) and (min-width: 576px) {
      margin-top: $spacing-SM * -1;
    }
    &::before {
      @extend %material-icon;
      color: var(--primary-color);
      font-size: 16px;
      transform: translateY(2px);
      content: 'business';
      margin-right: $spacing-XS * 1.75;
    }
  }

  &__reachability {
    display: flex;
    flex-direction: row;
    @include lg-up {
      margin-bottom: $spacing-SM * 1.5;
    }
  }

  &__phone {
    @extend %font-sans-MD;

    display: block;
    margin-bottom: $spacing-MD * 1;
    color: var(--primary-color-darker-12);
    text-decoration: underline;

    &:hover {
      color: var(--primary-color);
    }
    &::before {
      @extend %material-icon;
      margin-right: $spacing-XS * 1.75;
      color: var(--primary-color);
      font-size: 17px;
      transform: translateY(2.5px);
      content: 'phone';
    }
  }

  &__measured-distance {
    @extend %font-sans-MD;

    color: $text-dark-secondary;
    margin-right: $spacing-SM;
    line-height: 16px;
    &::before {
      @extend %material-icon;

      color: var(--primary-color);
      font-size: 14px;
      transform: translateY(2px);
      content: 'location_on';
    }
  }

  &__running-distance {
    @extend %font-sans-MD;

    color: $text-dark-secondary;
    margin-right: $spacing-SM;
    line-height: 16px;
    &::before {
      @extend %material-icon;

      color: var(--primary-color);
      font-size: 14px;
      transform: translateY(2px);
      content: 'straighten';
    }
  }

  &__running-minutes {
    @extend %font-sans-MD;

    color: $text-dark-secondary;
    margin-right: $spacing-SM;
    line-height: 16px;
    &::before {
      @extend %material-icon;

      color: var(--primary-color);
      font-size: 16px;
      transform: translateY(2px);
      content: 'directions_run';
    }
  }

  &__driving-minutes {
    @extend %font-sans-MD;
    margin-bottom: $spacing-SM;
    color: $text-dark-secondary;

    line-height: 16px;
    &::before {
      @extend %material-icon;

      color: var(--primary-color);
      font-size: 13px;
      transform: translateY(10px);
      content: 'directions_car';
    }
  }

  &__walking-distance {
    @extend %font-sans-MD;
    line-height: 16px;
    color: $text-dark-secondary;
    margin-right: $spacing-SM;
    margin-bottom: $spacing-SM;
    @include lg-up {
      margin-bottom: $spacing-XS;
    }

    &::before {
      @extend %material-icon;

      color: var(--primary-color);
      font-size: 15px;
      transform: translateY(2px);
      content: 'directions_walk';
    }
  }

  &__opening-hours-remark {
    @extend %font-sans-SM;
    line-height: 19px;
    color: rgba(#000,0.56);
    margin-left: $spacing-MD * 1.3;
    position: relative;
    max-width: 80%;
    margin-top: $spacing-SM;
    &:before {
      color: $text-dark-disabled;
      position: absolute;
      left: -22px;
      top: 0px;
      font-size: 17px;
      content: 'info_outline';
      @extend %material-icon;
    }
  }

  &__opening-hours-season-header {
    display: grid;
    grid-template-columns: 1fr 28px;
    grid-template-areas: "title icon";
    cursor: pointer;
    width: 100%;
    color: var(--primary-color-darker-12);
    border-bottom: solid 1px rgba(#000, 0.1);
    padding-top: $spacing-SM*1.2;
    padding-bottom: $spacing-SM*1.25;
  }

  &__opening-hours-season--not-closable {
    .location-details__opening-hours-season-header {
      cursor: initial;
    }
    .location-details__opening-hours-season-header .location-details__opening-hours-season-header-icon{
      display: none;
    }
  }

  &__opening-hours-season-header-title{
    grid-area: title;
  }

  &__opening-hours-season-header-icon{
    grid-area: icon;
    @extend %material-icon;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary-color-darker-12);
    line-height: 0;
    text-align: center;
  }

  &__opening-hours-season {
    overflow: hidden;
    .location-details__opening-hours-container {
      height: 0;
    }
  }



  &__opening-hours-season--active {
    .location-details__opening-hours-container {
      padding-top: $spacing-MD;
      height: auto;
    }
  }

  &__description-opening-hours {
    position: relative;
    @extend %font-sans-MD;

    color: $text-dark-secondary;


    dt {
      vertical-align: top;
      font-weight: 400;
      color: $text-dark-primary;
      display: inline-block;
      margin-bottom: $spacing-XS;
      width: 38%;
    }
    dd {
      font-weight: 400;
      color: $text-dark-secondary;
      display: inline-block;
      margin-bottom: $spacing-XS;
      width: 50%;
    }
  }


  &__legal-note-opening-hours {
    @extend %font-sans-SM;
    font-style: italic;
    margin-top: $spacing-SM;
    margin-bottom: $spacing-SM;
    color: $text-dark-disabled;
  }

  &__description-opening-hours-none {
    color: $text-dark-disabled;
  }

  &__header {
    @media (max-height: $defined-iframe-max-height) {
      grid-template-rows: auto auto auto;
      grid-template-columns: 1fr 1fr 1fr;
    }
    display: grid;
    grid-row: 1/2;
    grid-column: 1;
    grid-template-columns: 1fr;
  }

  &__header-information {
    @media (max-height: $defined-iframe-max-height) {
      position: relative;
      -ms-grid-row: 1;
      -ms-grid-row-span: 2;
      grid-row: 1/3;
      -ms-grid-column: 1;
      -ms-grid-column-span: 3;
      grid-column: 1 / 4;

      padding: $locations-detail-content-inset $locations-detail-content-inset
        7px $locations-detail-content-inset;
    }
    grid-row: 1;
    grid-column: 1 / 4;
    border-bottom: solid 1px rgba(#000, 0.1);
    padding: $spacing-MD $spacing-MD 0 $spacing-MD;
  }

  &__type {
    @extend %font-sans-SM;
    margin-bottom: $spacing-XXS;
    margin-top: -$spacing-XS;
    color: var(--primary-color);
    text-transform: uppercase;
    @include md-up {
      margin-bottom: $spacing-XXS;
    }
    @media (max-height: $defined-iframe-max-height) {
      margin-bottom: 0;
    }
  }

  &__name {
    color: $text-dark-primary;
    font-weight: 400;
    @extend %display-LG;
    margin-bottom: $spacing-SM * 1.14;
    margin-top: $spacing-XXS * -0.5;
    @include md-up {
      margin-top: -$spacing-XXS;
      margin-bottom: $spacing-SM;
    }
  }

  &__rating-stars {
    display: inline;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__rating {
    @media (max-height: $defined-iframe-max-height) {
      margin-bottom: 0px;
    }

    .widget-ratings {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      height: 24px;
      @media (max-height: $defined-iframe-max-height) {
        max-height: 1px;
        margin-top: -3px;
      }
    }

    .widget-container {
      width: 22px;
      height: 22px;
      margin-right: 0;
      margin-left: 0;
      padding: 0 !important;
      vertical-align: top !important;
      @media (max-height: $defined-iframe-max-height) {
        width: 14px;
        height: 14px;
      }
    }

    .widget-svg {
      @media (max-height: $defined-iframe-max-height) {
        max-width: 14px;
        max-height: 14px;
      }
      max-width: 20px;
      max-height: 20px;
    }
  }

  &__rating-evaluations {
    @media (max-height: $defined-iframe-max-height) {
      padding-top: $spacing-XS;
      font-size: 11px;
      line-height: 0px;
    }
    @extend %font-sans-SM;
    color: rgba(#000, 0.46);
  }

  &__tab-buttons {
    display: flex;
    color: var(--primary-color-darker-15);;
    margin-bottom: $spacing-XS;
    border-bottom: solid 1px rgba(#000, 0.1);
    cursor: pointer;
    letter-spacing: 0.5px;
  }

  &__tabs {
    display: none;
    @media (max-height: $defined-iframe-max-height) {
      display: block;
    }
  }

  &__description-without-tabs {
    display: block;

    @media (max-height: $defined-iframe-max-height) {
      display: none;
    }
  }

  &__tab-button {
    flex-grow: 1;
    padding: $spacing-SM;
    text-align: center;
    width: 50%;

    &--active {
      font-weight: 400;
      color: var(--primary-color-darker-18);
      border-bottom: solid 3px var(--primary-color);
    }
  }

  &__tab-panel {
    padding: $locations-detail-content-inset;
    overflow: auto;
  }

  &__arrival {
    margin-left: $spacing-LG * 0.9;
    margin-bottom: $spacing-SM;
    position: relative;
    &::before {
      position: absolute;
      margin-left: $spacing-LG * -0.9;
    }
  }

  &__arrival-db {
    text-align: left;
    color: $text-dark-secondary;
    &:before {
      font-family: 'hotelmapy';
      margin-right: $spacing-XS;
      font-size: 20px;

      color: var(--primary-color);

      content: 'train';
    }
  }

  &__arrival-bus {
    text-align: left;
    &:before {
      font-size: 20px;
      margin-right: $spacing-XS;
      font-family: 'hotelmapy';
      color: var(--primary-color);
      content: 'bus';
    }
  }

  &__arrival-ubahn {
    text-align: left;
    &:before {
      font-family: 'hotelmapy';
      content: 'u_bahn';
      font-size: 20px;
    }

    svg {
      fill: var(--primary-color);
      path {
        fill: var(--primary-color);
      }
    }
  }

  &__arrival-sbahn {
    text-align: left;
    &:before {
      font-family: 'hotelmapy';
      content: 's';
      font-size: 20px;
    }
  }

  &__arrival-walking {
    text-align: left;
  }

  &__walkingDistance {
    text-align: left;
    &:before {
      @extend %material-icon;
      margin-right: $spacing-XS;
      color: var(--primary-color);
      font-size: 12px;
      content: 'place';
    }
  }

  &__opening-hours-title {
    text-align: left;
    color: $text-dark-secondary;
    margin-bottom: $spacing-SM;
    &:before {
      @extend %material-icon;
      margin-right: $spacing-XS;
      color: var(--primary-color);
      transform: translateY(1px);
      font-size: 16px;
      content: 'access_time';
      @include lg-up {
        transform: translateY(2px);
      }
      @include xl-up {
        transform: translateY(1px);
      }
    }
  }

  &__driving-minutes {
    text-align: left;
    &:before {
      @extend %material-icon;

      transform: translateY(1.5px);

      color: var(--primary-color);
      content: 'drive_eta';
      font-size: 16px;
    }
  }

  &__description-section {
    &:first-child {
      border-top: none;
    }
    border-top: solid 1px rgba(0, 0, 0, 0.1);
    padding: $locations-detail-content-inset * 1.15
      $locations-detail-content-inset 0 $locations-detail-content-inset;

    @media (max-height: $defined-iframe-max-height) {
      border-top: none;

      margin-bottom: $spacing-MD;
    }
  }

  &__contact-information {
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
    @media (max-height: $defined-iframe-max-height) {
      border-bottom: none;
      margin-bottom: $spacing-MD;
    }
  }
  &__description-text {
    margin-bottom: $spacing-MD;
  }

  &__heading {
    margin-bottom: $spacing-XS;
    text-align: center;
    font-weight: 500;
    border-top: solid 1px rgba(0, 0, 0, 0.1);
  }

  &__arrival-element {
    &:after {
      content: ', ';
    }
    &:last-of-type:after {
      content: '';
    }
  }
}

@keyframes enteringDescription {
  from {
    opacity: 0.8;
    transform: translateY(40px) scale(0.98);
  }
  to {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
}
