.filter-bar {
  overflow: hidden;
  @include sm-up {
    position: relative;
  }
  &__scroll-button {
    position: absolute;
    z-index: 300;
    background: var(--primary-color-lighter-36);
    height: 40px;
    border: 0;
    width: 23px;
    transform: translateY(55px);
    @include sm-up {
      transform: translateY(0px);
      height: 54px;
      width: 44px;
    }
    &:before {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      content: '';
      width: 100%;
      height: 100%;
      @extend %material-icon;
      color: $text-dark-secondary;
      font-size: 23px;
      @include sm-up {
        font-size: 32px;
      }
    }
  }
  &__scroll-button--left {
    display: none;
    left: 0;
    top: 0;
    border-right: 1px solid var(--primary-color-more-transparent-84);
    border-left: 1px solid var(--primary-color-more-transparent-84);
    border-top-right-radius: 300px;
    border-bottom-right-radius: 300px;
    box-shadow: 6px 0px 12px 0px rgba(#000,0.20);
    @include sm-up {
      display: inline-block;
    }
    &:before {
      content: 'chevron_left';
      transform: translateX(-3px);
      @include sm-up {
        transform: translateX(-1px);
      }
    }
  }
  &__scroll-button--right {
    right: 0;
    top: 0;
    border-left: 1px solid var(--primary-color-more-transparent-84);
    border-top-left-radius: 300px;
    border-bottom-left-radius: 300px;
    box-shadow: -2px 0px 12px 0px rgba(#000,0.20);
    &:before {
      content: 'chevron_right';
      transform: translateX(3px);
      @include sm-up {
        transform: translateX(1px);
      }
    }
  }
&__container {
  position: absolute;

  @include sm-up {
  position: relative;
    display: flex;
    height: 54px;
  }
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: 200;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-grid-row: 2 !important;
  -ms-grid-row-span: 1;
  grid-row: 2 / 3;
  -ms-grid-column: 1 !important;
  -ms-grid-column-span: 2;
  grid-column: 1 / 3;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 0;
  padding-left: 0;
  overflow-x: auto;
  overflow-y: hidden;
  list-style: none;
  border-bottom: solid 1px var(--primary-color-more-transparent-84);
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-left: $spacing-SM;
  background: var(--primary-color-lighter-36);
  overflow: -moz-scrollbars-none;
  &::-webkit-scrollbar {
    display: none;
    width: 0 !important;
  }
}


  @include sm-up {
    padding-left: 0;
    background: var(--primary-color-lighter-36);
    -ms-grid-row: 1 !important;
    -ms-grid-row-span: 1;
    grid-row: 1 / 2;
    -ms-grid-column: 2 !important;
    -ms-grid-column-span: 2;
    grid-column: 2 / 4;
    padding-left: $spacing-SM;
    background: var(--primary-color-lighter-36);
  }



  &__label {
    @extend %display-MD;
    display: none;
    font-weight: 400;
    line-height: 30px;
    text-transform: normal;
    color: $text-dark-secondary;
    @include lg-up {
      display: block;
      margin-right: $spacing-MD;
      padding-left: $spacing-MD;
      margin-top: auto;
      margin-bottom: auto;
      white-space: nowrap;
    }
  }

  &__location-type-container {
    max-height: 34px;
    width: auto;
    height: 54px;
  }

  &__location-type {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    align-self: center;
    flex-wrap: nowrap;
    white-space: nowrap;
    max-height: 34px;
    margin-right: $spacing-SM;
    padding: $spacing-XS $spacing-SM * 1.5;
    color: $text-dark-primary;
    font-weight: 400;
    background: $white;
    border: solid 1px var(--primary-color-lighter-28);
    border-radius: 80px;
    cursor: pointer;
    @extend %font-sans-MD;
    @include sm-up {
      max-height: 32px;
      line-height: 16px;
      padding: $spacing-XS *2 $spacing-SM * 1.5;
    }
    @include xl-up {
      line-height: 17px;
    }

    &--active {
      padding-right: $spacing-SM;
      color: var(--primary-color-darker-20);
      font-weight: 400;
      letter-spacing: 0.1px;
      border: none;
      background-color: var(--primary-color-lighter-30);
      box-shadow: none;

      .filter-bar__location-type-icon {
        color: var(--primary-color);
      }

      &::after {
        @extend %material-icon;
        margin-left: $spacing-XS;
        color: var(--primary-color);
        font-size: 14px;
        content: 'cancel';
      }
    }
  }

  &__location-type-icon {
    @extend %material-icon;
    margin-right: $spacing-XS * 1.5;
    color: var(--primary-color-lighter-11);
    font-size: 16px;
  }
}
