@import 'fonts/fonts.scss';

@import 'spacings.scss';

@import 'colors.scss';

@import 'breakpoints.scss';

$defined-iframe-max-height: 570px;

%icons {
  font-size: $baseUnit * 16;
  font-family: 'hotelmapy', sans-serif;

  -webkit-font-feature-settings: 'liga';
  -moz-font-feature-settings: 'liga=1';
  -moz-font-feature-settings: 'liga';
  -ms-font-feature-settings: 'liga' 1;
  -o-font-feature-settings: 'liga';
  font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-sans-XS,
%font-sans-XS {
  font-weight: 400;
  font-size: $baseUnit * 11;
  font-family: 'Roboto', sans-serif;
  line-height: $baseUnit * 14;
  letter-spacing: 0;
  @include md-up {
    font-size: $baseUnit * 11;
    line-height: $baseUnit * 24;
  }
}

.font-sans-SM,
%font-sans-SM {
  font-weight: 400;
  font-size: $baseUnit * 12;
  font-family: 'Roboto', sans-serif;
  line-height: $baseUnit * 24;
  letter-spacing: 0;
  @include md-up {
    font-size: $baseUnit * 12;
    line-height: $baseUnit * 24;
  }
}

.font-sans-MD,
%font-sans-MD {
  font-weight: 400;
  font-size: $baseUnit * 14;
  font-family: 'Roboto', sans-serif;
  line-height: $baseUnit * 24;
  letter-spacing: 0.25px;
  @include md-up {
    font-size: $baseUnit * 14;
    line-height: $baseUnit * 24;
  }
  @include xl-up {
    font-size: $baseUnit * 16;
    line-height: $baseUnit * 26;
  }
}

.display-XL,
%display-XL {
  font-size: $baseUnit * 36;
  font-family: 'Roboto', sans-serif;
  line-height: $baseUnit * 40;
  @include md-up {
    font-size: $baseUnit * 54;
    line-height: $baseUnit * 63;
  }
}

.display-LG,
%display-LG {
  font-family: 'Roboto', sans-serif;
  letter-spacing: $baseUnit * -0.18;
  @include sm-down {
    font-size: $baseUnit * 26;
    line-height: $baseUnit * 28;
  }
  @media (max-height: $defined-iframe-max-height) {
    font-size: $baseUnit * 26;
    line-height: $baseUnit * 28;
  }
  @include md-up {
    font-size: $baseUnit * 24;
    line-height: $baseUnit * 29;
  }
}

.display-MD,
%display-MD {
  font-size: $baseUnit * 20;
  font-family: 'Roboto', sans-serif;
  line-height: $baseUnit * 22;
  letter-spacing: $baseUnit * 0.94;
  @include md-up {
    font-size: $baseUnit * 18;
    line-height: $baseUnit * 24;
  }
}

.display-SM,
%display-SM {
  font-weight: 500;
  font-size: $baseUnit * 11;
  font-family: 'Roboto', sans-serif;
  line-height: $baseUnit * 13;
  @include md-up {
    font-size: $baseUnit * 13;
    line-height: $baseUnit * 18;
    letter-spacing: 1.4px;
  }
}

.display-XS,
%display-XS {
  font-weight: 600;
  font-size: $baseUnit * 14;
  font-family: 'Roboto', sans-serif;
  line-height: $baseUnit * 31;
  letter-spacing: $baseUnit * 0.47;
  @include md-up {
    font-size: $baseUnit * 16;
    line-height: $baseUnit * 31;
  }
}
