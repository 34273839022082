body {
  overflow: hidden;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}

.hotelmapy {
  z-index: 100;
  display: -ms-grid;
  display: grid;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  -ms-grid-row: 1 !important;
  -ms-grid-row-span: 2;
  grid-row: 1 / 3;
  -ms-grid-column: 1 !important;
  -ms-grid-column-span: 1;
  grid-column: 1 / 2;

  -ms-grid-row-align: stretch !important;
  align-self: stretch;
  height: 100vh;
  max-height: 100vh;
  overflow-y: hidden;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  position: relative;
  &::-webkit-scrollbar {
    display: none;
    width: 0 !important;
  }
  @include xs-down {
    -ms-grid-rows: $baseUnit * 51 $baseUnit * 48 minmax(auto, 1fr) minmax(30%, 320px) minmax(auto, 12px) minmax(auto, 116px) minmax(auto, 28px) !important;
    grid-template-rows:
    $baseUnit * 51 $baseUnit * 48 minmax(auto, 1fr) minmax(30%, 320px)
    minmax(auto, 12px) minmax(auto, 116px) minmax(auto, 28px) !important;
    -ms-grid-columns: 1fr 1fr !important;
    grid-template-columns: 1fr 1fr;
    grid-template-rows:
    $baseUnit * 51 $baseUnit * 48 minmax(auto, 1fr) minmax(30%, 320px)
    minmax(auto, 12px) minmax(auto, 116px) minmax(auto, 28px);
    grid-template-columns: 1fr 1fr;
    -ms-flex-item-align: stretch !important;
  }

  @include sm-up {
    grid-template-rows: 54px 1fr;
    grid-template-columns: 300px 375px 1fr;
    -ms-grid-row: 2 !important;
    -ms-grid-row-span: 1;
    grid-row: 2 / 3;
    -ms-grid-column: 2 !important;
    -ms-grid-column-span: 1;
    grid-column: 2 / 3;
    /*
    &--locations-hidden {
      display: grid;
      grid-template-rows: auto auto 1fr;
      grid-template-columns: 300px 375px 1fr;
    }
     */
  }
  @media (max-height: $defined-iframe-max-height) and (max-width: 576px) {
    grid-template-rows:
      $baseUnit * 51 $baseUnit * 48 minmax(auto, 1fr) minmax(30%, 320px)
      minmax(auto, 3px) minmax(auto, 116px) minmax(auto, 28px);
  }
}

.map {
  //overflow: hidden;
  z-index: 0;
  @include xs-down {

    -ms-grid-row: 3 !important;
    -ms-grid-row-span: 5 !important;
    grid-row: 3/9;
    -ms-grid-column: 1 !important;
    -ms-grid-column-span: 2 !important;
    grid-column: 1/3;
  }
  @include sm-up {
    -ms-grid-row: 2 !important;
    -ms-grid-row-span: 2;
    grid-row: 2/4;
    -ms-grid-column: 2 !important;
    -ms-grid-column-span: 2;
    grid-column: 2/4;
  }
  @include xl-up {
    -ms-grid-row: 2;
    -ms-grid-row-span: 3;
    grid-row: 2 / 5;
    -ms-grid-column: 2 !important;
    -ms-grid-column-span: 3;
    grid-column: 2 / 5;
  }

  &__google-map {
    display: -ms-grid;
    display: grid;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    -ms-flex-item-align: stretch !important;
    -ms-grid-row-align: stretch !important;
    align-self: stretch;
  }

  &__button {
    position: absolute;
    top: 110px;
    right: 2%;
    z-index: 200;
    width: 48px;
    height: 48px;
    padding: 8px;
    background-color: transparent;
    border: none;
    border-radius: 2px;
    outline: none;
    cursor: pointer;
    @include sm-up {
      top: 70px;
      right: 20px;
    }

    &-icon {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      background-color: #fff;
      width: 35px;
      height: 35px;
      box-shadow: 0px 1px 4px -1px rgba(#000, 0.3);
      @include sm-up {
        width: 40px;
        height: 40px;
      }
      @extend %material-icon;
      position: relative;
      &::after {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        color: $white;
        position: absolute;
        bottom: 0;
        left: 0;
        top:0;
        right: 0;
        line-height: 0px;
        color: $text-dark-secondary;
        font-size: 38px;
        width: 35px;
        height: 35px;
        @include sm-up {
          width: 40px;
          height: 40px;
        }
        @ex
      }

      &--fullscreen {
        &::after {
          content: 'fullscreen';
        }
      }

      &--fullscreen-exit {
        &::after {
          content: 'fullscreen_exit';
        }
      }
    }
  }
}
