html {
  font-size: 16px;
}

$baseUnit: 1rem/16;

$spacing-XXS: $baseUnit * 2;
$spacing-XS: $baseUnit * 4;
$spacing-SM: $baseUnit * 8;
$spacing-MD: $baseUnit * 16;
$spacing-LG: $baseUnit * 32;
$spacing-XL: $baseUnit * 64;
$spacing-XXL: $baseUnit * 128;
$spacing-XXXL: $baseUnit * 256;
