$defined-iframe-max-height: 500px;

$screen-xs-min: 0px;

// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 1120px;

// Large tablets and desktops
$screen-xl-min: 1450px;


// Small devices
@mixin xs-down {
  @media (max-width: #{$screen-sm-min}) {
    @content;
  }
}

// Small devices
@mixin xs-up {
  @media (min-width: #{$screen-xs-min}) {
    @content;
  }
}

// Small devices
@mixin sm-up {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

// Small devices
@mixin sm-down {
  @media (max-width: #{$screen-sm-min}) {
    @content;
  }
}

// Medium devices
@mixin md-up {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

// Large devices
@mixin lg-up {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

// Extra large devices
@mixin xl-up {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}


// Custom devices
@mixin rwd($screen) {
  @media (min-width: $screen+'px') {
    @content;
  }
}
