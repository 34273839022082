.tabs {
  display: flex;
  flex-direction: row;
  grid-row: 1;
  grid-column: 1 / 3;

  border-bottom: solid 1px var(--primary-color);
  @include sm-up {
    -ms-grid-row: 1 !important;
    grid-row: 1;
    -ms-grid-column: 1 !important;
    -ms-grid-column-span: 1;
    grid-column: 1/2;
  }



  &__tab {
    position: relative;
    z-index: 2;
    display: flex;
    flex-grow: 1;
    grid-row: 1;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: var(--primary-color-darker-5);
    font-size: 16px;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    background: var(--primary-color-lighter-36);
    border-top: none;
    border-bottom: solid 1px var(--tab-bar-color);
    box-shadow: 0px 0px 0px 0px var(--tab-bar-color);
    cursor: pointer;
    @extend %display-MD;

    &--active {
      font-weight: 400;
      color: var(--primary-color-darker-18);
      &:before {
        position: absolute;
        bottom: 0;
        width: 72%;
        height: 3px;
        background-color: var(--tab-bar-color);
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        content: '';
      }
    }
  }
}
