$location-item-max-width-mobile: 280px;

.locations {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: calc(100%);
  height: 100%;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  z-index: 1;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-grid-row: 3 !important;
  -ms-grid-row-span: 1 !important;
  grid-row: 6/9 !important;
  -ms-grid-column: 1 !important;
  -ms-grid-column-span: 2 !important;
  grid-column: 1/4 !important;
  padding-bottom: 0;
  font-size: 0.65rem;
  font-family: Helvetica, sans-serif;
  column-gap: 10px;
  background: transparent;
  margin-right: -20px;
  opacity: 1;
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currentlysupported by Chrome, Opera and Firefox */
  -webkit-touch-callout: none; /* iOS Safari */
  scrollbar-width: none;
  overflow: -moz-scrollbars-none;
  @include sm-up {
    -ms-grid-row: 2 !important;
    -ms-grid-row-span: 6 !important;
    grid-row: 2/8 !important;
    -ms-grid-column: 1 !important;
    -ms-grid-column-span: 1 !important;
    grid-column: 1/2 !important;
    grid-area: locations;
    background: #ffffff;
  }

  &__card {
    background-color: red;
    margin-left: $spacing-SM;
  }

  img {
    background: rgba(#000, 0.15);
  }

  &::-webkit-scrollbar {
    display: none;
    width: 0 !important;
  }

  @include sm-up {
    flex-direction: column;
    -ms-grid-row: 2 !important;
    -ms-grid-row-span: 1;
    grid-row: 2 / 3;
    -ms-grid-column: 1 !important;
    -ms-grid-column-span: 3;
    grid-column: 1/2;
    height: auto;
    margin-bottom: initial;
    overflow-y: scroll;
    overflow-x: hidden;

    &:last-child {
      margin-bottom: initial;
    }
  }

  &__location-container {
    @include xs-down {
      position: absolute;
      bottom: 0;
      animation-name: enteringCards;
      animation-duration: 0.5s;
      transition-timing-function: ease-in;
    }

    @include sm-up {
      position: relative;
      grid-template-columns: auto;

    }
    @include sm-up {
      background: #fff;
    }
  }

  .swiper-wrapper {
    @media (max-height: $defined-iframe-max-height) {
      margin-left: -4.5%;
    }
  }

  &__information-button {
    grid-area: button;
    display: flex;
    align-items: center;
    z-index: 0;
    margin-top: auto;
    margin-bottom: auto;

    color: white;
    text-align: center;
    background: transparent;
    border: none;
    border-radius: 28px;
    opacity: 1;
    @extend %font-sans-MD;
    padding: 0;

    &:focus {
      outline: none;
    }
    @include sm-up {
      display: none;
    }
  }

  &__information-button-shape {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;

    z-index: 0;

    padding: $spacing-XS * 1.5 $spacing-SM * 1.3 $spacing-XS * 1.5 $spacing-XS *
      1.3;
    color: white;
    color: var(--primary-color);
    text-align: center;
    background-color: white;
    border: solid 1px var(--primary-color-lighter-28);
    border-radius: 40px;
    opacity: 1;

    font-weight: 400;
    line-height: 12px;
    @extend .font-sans-SM;

    &:focus {
      outline: none;
    }
    @include sm-up {
      display: none;
    }
  }

  &__information-button-icon {
    @extend %material-icon;
    font-size: 15px;
    display: inline-block;
    margin-right: $spacing-XS * 0.5;
  }

  &__location {
    z-index: 1;
    display: grid;
    -ms-grid-row: 1!important;
    grid-row: 1;
    grid-template-areas: 'details button';
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    box-sizing: border-box;
    max-height: 110px;
    background: white;
    border-width: 1px;
    max-width: 100%;
    cursor: pointer;
    @include xs-down {
      display: grid;
      margin-left: 7px;
      margin-right: 7px;
      border-radius: 8px;
      -webkit-box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.3);
      box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.3);
      width: $location-item-max-width-mobile;
    }
    @include sm-up {
      width: 100%;
      min-width: $baseUnit * 250;
      min-height: $baseUnit * 66;
      padding: $spacing-SM 0px $spacing-SM $spacing-SM;
      border-top: solid 1px rgba(#000,0.0);
      border-bottom: solid 1px rgba(#000,0.05);
    }

    &.swiper-slide {
      -webkit-transform: translateZ(0);
      -webkit-backface-visibility: hidden;
      @include xs-down {
        max-width: $location-item-max-width-mobile;
      }
    }
    &:hover {
      @include sm-up {
        background-color: var(--primary-color-more-transparent-93);

        .locations__name {
          text-decoration: underline;
        }
      }
    }

    &--selected {
      @include sm-up {
        box-sizing: border-box;
        background-color: var(--primary-color-more-transparent-93);
        border-bottom: solid 1px var(--primary-color-more-transparent-80);
        border-top: solid 1px var(--primary-color-more-transparent-80);
      }
    }
    &--hightlighted {
      .locations__name {
        text-decoration: none;
      }
      @include sm-up {
        background-color: var(--primary-color-more-transparent-93);
        border-top: solid 1px var(--primary-color-more-transparent-85);
        border-bottom: solid 1px var(--primary-color-more-transparent-85);
      }
    }
  }

  &__information {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-row: 1/2;
    -ms-grid-column: 1 !important;
    -ms-grid-column-span: 3 !important;
    grid-column: 1/4;
    padding: $spacing-SM * 1.75 $spacing-MD $spacing-SM $spacing-SM * 1.25;
    display: grid;
    grid-template-areas:
      'title title title'
      'reachability reachability button';
    grid-template-rows: auto auto;
    grid-template-columns: auto 1fr auto;

    @include sm-up {
      padding: 0 $spacing-XS 0 $spacing-XS;
      grid-template-areas:
      'title title title'
      'icon reachability button';
      grid-template-rows: auto auto;
      grid-template-columns: auto 1fr auto;
    }



  }

  &__reachability {
    grid-area: reachability;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
    white-space: nowrap;
    @extend .font-sans-MD;
    @include sm-up {
      margin-bottom: 0;
    }
    %locations__reachability-icon {
      @extend %material-icon;
      display: inline;
      height: 12px;
      margin-right: $spacing-XS;
      margin-bottom: 2px;
      color: var(--primary-color);
      font-size: 12px;
    }

    %locations__details {
      display: flex;
      align-items: center;
      color: $text-dark-secondary;
      font-size: 12px;
      letter-spacing: 1px;
    }

    &-walking-distance {
      display: block;
      margin-right: $spacing-XS * 1.5;
      @extend %locations__details;

      &:before {
        @extend %locations__reachability-icon;
        content: 'room';
      }
    }

    &-running-distance {
      display: block;
      margin-right: $spacing-XS * 2;
      @extend %locations__details;
      &::before {
        @extend %material-icon;
        margin-right: $spacing-XS*1.25;
        color: var(--primary-color);
        font-size: 14px;
        transform: translateY(0px);
        content: 'straighten';
      }
    }

    &-running-minutes {
      display: block;
      margin-right: $spacing-XS * 1.5;
      @extend %locations__details;
      &::before {
        @extend %material-icon;
        margin-right: $spacing-XS*1.25;
        color: var(--primary-color);
        font-size: 14px;
        transform: translateY(0px);
        content: 'directions_run';
      }
    }

    &-driving-minutes {
      display: block;
      margin-right: $spacing-XS * 1.5;
      @extend %locations__details;

      &:before {
        @extend %locations__reachability-icon;
        content: 'directions_car';
      }
    }
    &-walking-minutes {
      display: block;
      margin-right: $spacing-XS * 1.5;
      @extend %locations__details;

      &:before {
        @extend %locations__reachability-icon;
        content: 'directions_walk';
      }
    }
  }

  &__title {
    z-index: 1;
    grid-area: title;
    width: 100%;
    margin-bottom: $spacing-XS;
    @include sm-up {
      margin-bottom: $spacing-XS * -0.5;
    }
  }

  &__icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    grid-area: icon;
    width: 13px;
    height: 13px;
    background-color: var(--primary-color);
    border-radius: 100%;
    @extend %material-icon;
    color: white;
    font-size: 11px;
    margin-right: 4px;
    transform: translateY(1px);
    @include sm-up {
      width: 15px;
      height: 15px;
      font-size: 11px;
      transform: translateY(1px);
      margin-right: 3px;
    }
  }

  &__type {
    @extend %font-sans-XS;
    margin-bottom: $spacing-XS*0.8;
    color: var(--primary-color);
    letter-spacing: 0.8px;
    text-transform: uppercase;

    @include md-up {
      margin-bottom: $spacing-XS * -1;
    }
  }

  &__name {
    @extend %display-MD;
    align-items: flex-start;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    font-weight: 400;
    color: $text-dark-primary;
    @include sm-up {
      margin-bottom: $spacing-XS * 0.5;
    }
  }
}
@keyframes enteringCards {
  from {
    transform: translateX(240px);
  }
  to {
    transform: translateX(20px);
  }
}
