/*name*/
$highlight: #414141;
$lightgrey: #414141;
$verylightgrey: #f5f3f1;
$white: #fff;
$purple: #7a8099;

$black-84: rgba(#000, 0.84);
$black-74: rgba(#000, 0.74);
$black-64: rgba(#000, 0.64);
$black-54: rgba(#000, 0.54);
$black-34: rgba(#000, 0.34);

$text-dark-primary: $black-84;
$text-dark-secondary: $black-64;
$text-dark-disabled: $black-34;
