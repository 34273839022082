.action-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  list-style-type: none;
  margin: 0;
  padding-top: $spacing-MD;

  padding-left: $spacing-XS;
  padding-right: $spacing-XS;
  border-bottom: solid 1px rgba(#000, 0.1);
  margin-bottom: $spacing-SM * 1.5;
  border-bottom: 0px;
  border-top: solid 1px rgba(#000, 0.1);
  @media (max-height: $defined-iframe-max-height) {
    padding-bottom: $spacing-SM;
    border-bottom: solid 1px rgba(#000, 0.1);
    border-top: none;
    margin-top: $spacing-MD * -1;
    margin-bottom: $spacing-SM;
  }
  &__action {
    text-align: center;
    justify-content: flex-start;
    margin-right: auto;
    color: var(--primary-color);
    transition-duration: 0.2s;
    text-decoration: none;
    width: 33%;
    &:hover {
      text-decoration: none;
      color: var(--primary-color-darker-10);

      .action-bar__action-icon {
        border: solid 1px var(--primary-color-darker-15);
      }
    }
  }

  &__action--disabled {
    &:hover {
      text-decoration: none;
      color: transparentize($color: $text-dark-disabled, $amount: 0.2);

      .action-bar__action-icon {
        border: solid 1px
          transparentize($color: $text-dark-disabled, $amount: 0.2);
      }
    }
    .action-bar__action-icon {
      border-color: transparentize($color: $text-dark-disabled, $amount: 0.2);
      color: transparentize($color: $text-dark-disabled, $amount: 0.2);
    }
    .action-bar__action-label {
      color: $text-dark-disabled;
      color: transparentize($color: $text-dark-disabled, $amount: 0.15);
    }
    pointer-events: none;
  }

  &__action-label {
    @extend %font-sans-SM;
    text-transform: uppercase;
  }
  &__action-icon {
    margin-left: auto;
    margin-right: auto;
    border: solid 1px var(--primary-color);
    padding: $spacing-XS * 1.5;
    border-radius: 100%;
    @extend %material-icon;
  }
}
