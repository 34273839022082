.navigation-bar {
  z-index: 200;
  z-index: 200;
  display: flex;
  display: flex;
  flex-direction: row;
  grid-row: 2 / 3;
  grid-column: 1 / 3;
  align-items: center;
  height: 48px;
  margin: 0px;
  padding: 0px;
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  border-bottom: solid 1px var(--primary-color-more-transparent-84);

  &::-webkit-scrollbar {
    display: none;
    width: 0 !important;
  }
  background-color: $white;
  @include sm-down {
    padding-right: $spacing-XS * 1.5;
    padding-left: $spacing-XS * 1.5;
    background-color: none;
    justify-content: space-around;
    background: var(--primary-color-lighter-36);
  }
  @include sm-up {
    grid-row: 1 / 2;
    grid-column: 2 / 4;
    height: 54px;
    background: var(--primary-color-lighter-36);
    padding-left: $spacing-SM;
  }
  @include lg-up {
    padding-left: 0;
  }
  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    list-style: none;
    cursor: pointer;
  }

  &__label {
    @extend %display-MD;
    display: none;
    font-weight: 400;
    line-height: 30px;
    text-transform: normal;
    color: $text-dark-secondary;

    @include lg-up {
      display: block;
      margin-right: $spacing-MD;
      white-space: nowrap;
      padding-left: 1rem;
    }
  }

  &__name {
    position: relative;
    display: flex;
    align-items: center;
    padding: $spacing-XS * 1.5 $spacing-SM * 1.4 $spacing-XS * 1.3 $spacing-SM;
    color: $text-dark-primary;
    line-height: 0px;
    white-space: nowrap;
    border: solid 2px var(--primary-color-more-transparent-100);
    border-radius: 6px;
    box-sizing: border-box;
    @extend %font-sans-MD;
    &:before {
      @extend %material-icon;
      display: flex;
      align-items: center;
      height: 20px;
      margin-right: $spacing-SM;
      color: var(--primary-color-lighter-11);
      font-size: 16px;
      line-height: 0px;
    }

    &--active {
      color: var(--primary-color-darker-20);
      font-weight: 400;
      background-color: var(--primary-color-lighter-30);
      &:before {
        color: var(--primary-color);
      }
    }

    &--bus {
      &:before {
        content: 'directions_bus';
      }
    }

    &--car {
      &:before {
        content: 'directions_car';
      }
    }

    &--flight {
      &:before {
        content: 'flight';
      }
    }

    &--bike {
      &:before {
        content: 'pedal_bike';
      }
    }
  }
}
